<script>
import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

import SelectAvailableJournalDocumentTemplateModal from '@/components/modal/SelectAvailableJournalDocumentTemplateModal.vue'
import { inputFieldMixins, toastMixins } from '@/mixins'

import branchService from '@/services/branch/branch'

/*
Props: {
  mode: 'CREATE' | 'UPDATE'
  journalBookProp: {  }
  newDocumentNumberProp: string
  newDocumentIdProp: string
}
Events: {
  savedButtonClicked: {
    journalTransactionHeader: {  }
  }
  templateSelected: {  }
}
*/

export default {
  mixins: [inputFieldMixins, toastMixins],
  components: { SelectAvailableJournalDocumentTemplateModal },
  props: {
    mode: String,
    journalBookProp: Object,
    newDocumentNumberProp: String,
    newDocumentIdProp: String,
    journalTransactionHeaderProp: Object
  },
  data() {
    return {
      journalBook: null,
      documentIdPatternType: null,
      selectedTemplates: [],
      displaySelectAvailableJournalDocumentTemplateModal: false,
      branchOptions: [],
      errMsg: null,
      /** journalTransactionHeader data */
      journalCode: null,
      documentNumber: null,
      documentId: null,
      documentDate: null,
      documentDateTH: null,
      description: null,
      remark: null,
      totalCredit: null,
      totalDebit: null,
      deletedAt: null,
      isMockPosted: null,
      isReverse: null,
      itemCount: null,
      postedAt: null,
      branchId: null
    }
  },
  computed: {
    ...mapState({
      stateAppLoading: 'appLoading'
    })
  },
  watch: {
    journalBookProp() {
      this.setJournalHeader()
    },
    newDocumentNumberProp() {
      this.setJournalHeader()
    },
    newDocumentIdProp() {
      this.setJournalHeader()
    },
    journalTransactionHeaderProp() {
      this.reAssignJournalTransactionHeader()
    },
    documentId(val) {
      if (this.documentIdPatternType === 'SPECIAL' && this.mode === 'CREATE') {
        this.documentIdDocumentDateTHMatchCheck('documentId')
      } else {
        if (this.documentId !== val) this.setDocumentId(this.documentId)
      }
    },
    documentDateTH(val) {
      if (this.documentIdPatternType === 'SPECIAL' && this.mode === 'CREATE') {
        this.documentIdDocumentDateTHMatchCheck('documentDateTH')
      } else {
        if (this.documentDateTH !== val)
          this.setDocumentDateTH(this.documentDateTH)
      }
    }
  },
  created() {
    this.setJournalHeader()
    this.fetchBranchOptions()
  },
  methods: {
    setJournalHeader() {
      this.setDocumentNumber(this.newDocumentNumberProp)
      this.setDocumentId(this.newDocumentIdProp)
      this.setDocumentDateTH()
      this.journalBook = this.journalBookProp
      this.documentIdPatternType = this.journalBook?.documentIdPattern.pattern
      this.journalCode = this.journalBook?.journalCode
    },
    reAssignJournalTransactionHeader() {
      const journalHeaderProp = { ...this.journalTransactionHeaderProp }
      this.journalCode = journalHeaderProp.journalCode
      this.documentNumber = journalHeaderProp.documentNumber
      this.documentId = journalHeaderProp.documentId
      this.documentDate = journalHeaderProp.documentDate
      this.documentDateTH = journalHeaderProp.documentDateTH
      this.description = journalHeaderProp.description
      this.remark = journalHeaderProp.remark
      this.totalCredit = journalHeaderProp.totalCredit
      this.totalDebit = journalHeaderProp.totalDebit
      this.deletedAt = journalHeaderProp.deletedAt
      this.isMockPosted = journalHeaderProp.isMockPosted
      this.isReverse = journalHeaderProp.isReverse
      this.itemCount = journalHeaderProp.itemCount
      this.postedAt = journalHeaderProp.postedAt
      this.branchId = journalHeaderProp.branchId
    },
    setDocumentNumber(newDocumentNumber) {
      this.documentNumber = newDocumentNumber
    },
    setDocumentId(newDocumentId) {
      this.documentId = newDocumentId
    },
    setDocumentDateTH(dateFormat = undefined) {
      const dateTh = this.transformToBuddhistYear(
        moment(dateFormat)
          .utcOffset('+0700')
          .format('YYYY-MM-DD')
      )
      this.documentDateTH = dateTh
    },
    transformToBuddhistYear(christianFormat) {
      // expect christianFormat is in this format: 2022-01-01
      const ddmmyy = christianFormat.split('-')
      const year = ddmmyy[0]
      const month = ddmmyy[1]
      const date = ddmmyy[2]
      return `${date}/${month}/${Number(year) + 543}`
    },
    async fetchBranchOptions() {
      try {
        const res = await branchService.getBranchOptions()
        this.branchOptions = [...res.data.data]
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    buttonClicked(type) {
      switch (type) {
        case 'SAVE':
          const journalHeaderBody = {
            journalCode: this.journalCode,
            documentNumber: this.documentNumber,
            documentId: this.documentId,
            documentDate: this.documentDate,
            documentDateTH: this.documentDateTH,
            description: this.description,
            remark: this.remark,
            totalCredit: this.totalCredit,
            totalDebit: this.totalDebit,
            deletedAt: this.deletedAt,
            isMockPosted: this.isMockPosted,
            isReverse: this.isReverse,
            itemCount: this.itemCount,
            postedAt: this.postedAt,
            branchId: this.branchId
          }
          this.$emit('saveButtonClicked', {
            journalTransactionHeader: journalHeaderBody
          })
          break
        case 'CANCEL':
          this.$router.replace({
            name: 'ge2gjnl.detail',
            params: {
              journalBookId: `${this.journalBook.id}`
            }
          })
          break
        default:
          break
      }
    },
    templateSelectedHandler(template) {
      this.selectedTemplates.push(template)
      this.$emit('templateSelected', template)
    },
    useStyles(style) {
      switch (style) {
        case 'div-class':
          return 'd-flex align-items-end mb-2'
        case 'label-style':
          return { width: '150px' }
        case 'short-form-style':
          return { width: '40%' }
        case 'long-form-style':
          return { width: '60%' }
        default:
          return {}
      }
    },
    documentIdDocumentDateTHMatchCheck(changedField) {
      if (this.documentId && this.documentDateTH && this.journalBookProp) {
        const { journalCode, documentIdPattern } = this.journalBookProp
        const { yearType } = documentIdPattern

        switch (changedField) {
          case 'documentId':
            const journalBookCodeLength = journalCode.trim().length
            const yearMonthDateLength = 6
            try {
              if (
                this.documentId.length >=
                journalBookCodeLength + yearMonthDateLength
              ) {
                const yymmdd = this.documentId.slice(journalCode.length)
                const year = yymmdd.slice(0, 2)
                const month = yymmdd.slice(2, 4)
                const date = yymmdd.slice(4)
                const validYear =
                  yearType === 'BE'
                    ? `${Number(`25${year}`) - 543}`
                    : `20${year}`

                if (!this.isDateValid(`${validYear}-${month}-${date}`)) {
                  throw new Error('')
                }

                this.setDocumentDateTH(
                  `20${validYear.slice(2)}-${month}-${date}`
                )
              }
            } catch (err) {
              this.errMsg = 'เลขที่เอกสารมีค่าที่ไม่ถูกต้อง ลองใหม่อีกครั้ง'
            }
            break
          case 'documentDateTH':
            try {
              if (this.documentDateTH.length >= 10) {
                const [date, month, year] = this.documentDateTH.split('/')
                const validYear =
                  yearType === 'BE' ? year : `${Number(year) - 543}`

                if (!this.isDateValid(`${validYear}-${month}-${date}`)) {
                  throw new Error('')
                }

                const slicedValidYear = validYear.slice(2)
                const newDocumentId = `${journalCode}${slicedValidYear}${month}${date}`
                this.setDocumentId(newDocumentId)
              }
            } catch (err) {
              this.errMsg = 'วันที่เอกสารมีค่าไม่ถูกต้อง ลองใหม่อีกครั้ง'
            }
            break
          default:
            break
        }
      }
    },
    isDateValid(dateFormat) {
      const expectFormat = 'XXXX-XX-XX'

      if (expectFormat.length !== dateFormat.length) return false

      const dateSplitted = dateFormat.split('-')
      if (dateSplitted.length !== 3) return false

      return moment(dateFormat).isValid()
    }
  }
}
</script>

<template>
  <b-row class="mx-1 mx-xl-4">
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px;">
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-center justify-content-md-start"
          cols="12"
          md="6"
          lg="7"
          xl="8"
        >
          <b-button variant="primary" class="mr-3 px-5" disabled>
            {{ journalBook ? journalBook.journalCode : '-' }}
          </b-button>
          <span>{{ journalBook ? journalBook.journalName : '-' }}</span>
        </b-col>
        <b-col class="mt-3 mt-md-0" cols="12" md="6" lg="5" xl="4">
          <b-row align-h="center" align-v="center" class="h-100">
            <b-col cols="5">
              <b-button
                class="w-100"
                variant="primary"
                @click="buttonClicked('SAVE')"
                :disabled="stateAppLoading"
              >
                <div v-if="!stateAppLoading">
                  <span>บันทึก</span>
                </div>
                <div v-else>
                  <b-spinner small></b-spinner>
                </div>
              </b-button>
            </b-col>
            <b-col cols="5">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="buttonClicked('CANCEL')"
                :disabled="stateAppLoading"
              >
                ยกเลิก
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />

      <!-- documentId / documentDate -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              เลขที่เอกสาร
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('short-form-style')"
              v-model="documentId"
              :disabled="
                ['COMPUTER'].includes(documentIdPatternType) ||
                  mode !== 'CREATE'
              "
            ></b-form-input>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              วันที่เอกสาร
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('short-form-style')"
              v-model="documentDateTH"
              @keypress.enter="mxFocusNextInputField"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- documentNumber / description -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              เลขที่อ้างอิง
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="documentNumber"
              :disabled="true"
            ></b-form-input>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              อ้างอิง
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="description"
              @keypress.enter="mxFocusNextInputField"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- branch / remark -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              สาขา
            </label>
            <b-form-select
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="branchId"
              :options="branchOptions"
            ></b-form-select>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              หมายเหตุ
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="remark"
              @keypress.enter="mxFocusNextInputField"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- journalDocumentTemplate -->
      <b-row>
        <b-col
          cols="12"
          md="4"
          lg="3"
          class="d-flex justify-content-start align-items-center mt-2 mt-lg-3 mb-1"
        >
          <b-button
            variant="outline-primary"
            size="md"
            class="mr-3 text-nowrap"
            @click="displaySelectAvailableJournalDocumentTemplateModal = true"
          >
            ต้นแบบป้อนรายการ
          </b-button>
          <span class="text-nowrap text-secondary">{{
            selectedTemplates.length > 0
              ? `${
                  selectedTemplates[selectedTemplates.length - 1].templateCode
                }`
              : ''
          }}</span>
          <!-- select available journal document template modal -->
          <select-available-journal-document-template-modal
            :displayModalProp="
              displaySelectAvailableJournalDocumentTemplateModal
            "
            :journalCodeProp="journalBook ? journalBook.journalCode : ''"
            @modalClosed="
              displaySelectAvailableJournalDocumentTemplateModal = false
            "
            @templateSelected="templateSelectedHandler"
          ></select-available-journal-document-template-modal>
          <!--  -->
        </b-col>
        <b-col
          cols="12"
          md="8"
          lg="9"
          class="d-flex justify-content-start justify-content-md-end align-items-center mt-2 mt-lg-3 mb-1"
        >
          <div v-if="deletedAt" class="font-weight-bold text-danger pr-4">
            <span>( ยกเลิก )</span>
          </div>
          <div v-if="postedAt" class="font-weight-bold text-success pr-4">
            <span>( ผ่านรายการแล้ว )</span>
          </div>
          <div
            v-if="mode === 'CREATE' || isMockPosted"
            class="font-weight-bold pr-4"
          >
            <span>( จำลองผ่านรายการ )</span>
          </div>
          <b-form-checkbox class="pr-3" :disabled="true">
            กลับรายการ
          </b-form-checkbox>
        </b-col>
      </b-row>

      <error-modal
        :displayProp="!!errMsg"
        :errorMessageProp="errMsg"
        @modalClosed="errMsg = null"
      ></error-modal>
    </b-col>
  </b-row>
</template>

<style></style>
