import { Decimal } from 'decimal.js'

// mixins required: toastMixins
// library required: lodash

export default {
  methods: {
    mxValidateRequest(payload) {
      const { journalHeader, journalDetails } = payload

      // validate documentDateTH
      const { documentDateTH: documentDateTHInput } = journalHeader
      const ddmmyyArray = documentDateTHInput.split('/')
      try {
        if (ddmmyyArray.length !== 3)
          throw new Error('document_date_format_error')
        let day = +ddmmyyArray[0]
        let month = +ddmmyyArray[1]
        let year = +ddmmyyArray[2]
        if (
          !(
            day >= 1 &&
            day <= 31 &&
            month >= 1 &&
            month <= 12 &&
            year >= 544 &&
            year <= 9999
          )
        )
          throw new Error('document_date_format_error')
      } catch (err) {
        this.errMsg = 'วันที่เอกสารต้องเป็นรูปแบบ xx/xx/25xx เท่านั้น'
        return
      }

      // validate each detail should have account number
      for (let detail of journalDetails) {
        if (
          _.isNull(detail.accountNumber) ||
          detail.accountNumber.trim().length <= 0
        ) {
          this.errMsg = 'มีบางรายการยังไม่ได้เลือกบัญชี'
          return
        }
      }

      // validate each detail should have debit or credit but together does not allow
      for (let detail of journalDetails) {
        const { debitAmount, creditAmount } = detail

        // in the future, if aj.vira wants to enable this check back, we will uncomment it.
        // const debitNullcreditNull =
        //   _.isNull(debitAmount) && _.isNull(creditAmount)
        // const debitEmptycreditNull =
        //   !_.isNull(debitAmount) &&
        //   debitAmount.trim().length <= 0 &&
        //   _.isNull(creditAmount)
        // const debitNullcreditEmpty =
        //   _.isNull(debitAmount) &&
        //   !_.isNull(creditAmount) &&
        //   creditAmount.trim().length <= 0
        // const debitEmptycreditEmpty =
        //   !_.isNull(debitAmount) &&
        //   !_.isNull(creditAmount) &&
        //   debitAmount.trim().length <= 0 &&
        //   creditAmount.trim().length <= 0
        // if (
        //   debitNullcreditNull ||
        //   debitEmptycreditNull ||
        //   debitNullcreditEmpty ||
        //   debitEmptycreditEmpty
        // ) {
        //   this.errMsg = 'มีรายการที่ยังไม่ได้กรอกเดบิตหรือเครดิต'
        //   return
        // }

        const debitFillcreditFill =
          !_.isNull(debitAmount) &&
          !_.isNull(creditAmount) &&
          debitAmount.trim().length > 0 &&
          creditAmount.trim().length > 0
        if (debitFillcreditFill) {
          this.errMsg = 'ไม่สามารถมีรายการที่กรอกเดบิตและเครดิตพร้อมกันได้'
          return
        }
      }

      // validate total debit amount should be equal to total credit amount
      let totalDebit = Decimal(0)
      let totalCredit = Decimal(0)
      for (let detail of journalDetails) {
        if (
          !_.isNull(detail.debitAmount) &&
          detail.debitAmount.trim().length > 0
        ) {
          totalDebit = totalDebit.plus(Decimal(+detail.debitAmount))
        }
        if (
          !_.isNull(detail.creditAmount) &&
          detail.creditAmount.trim().length > 0
        ) {
          totalCredit = totalCredit.plus(Decimal(+detail.creditAmount))
        }
      }
      totalDebit = totalDebit.toDP(2).toNumber()
      totalCredit = totalCredit.toDP(2).toNumber()
      if (totalDebit === totalCredit) {
        journalHeader.totalDebit = totalDebit
        journalHeader.totalCredit = totalCredit
      } else {
        this.errMsg = 'ยอดรวมเดบิตกับยอดรวมเครดิตไม่เท่ากัน'
        return
      }

      // convert number value to 2 decimal points
      journalHeader.totalDebit = Number(journalHeader.totalDebit).toFixed(2)
      journalHeader.totalCredit = Number(journalHeader.totalCredit).toFixed(2)
      journalDetails.forEach((detail) => {
        const { debitAmount, creditAmount } = detail
        if (!_.isNull(debitAmount) && debitAmount.trim().length > 0) {
          detail.debitAmount = Number(debitAmount).toFixed(2)
          detail.creditAmount = null
        } else if (!_.isNull(creditAmount) && creditAmount.trim().length > 0) {
          detail.debitAmount = null
          detail.creditAmount = Number(creditAmount).toFixed(2)
        }
      })

      // covert document date
      const ddmmyy = journalHeader.documentDateTH.split('/')
      const dd = `${ddmmyy[0]}`.padStart(2, '0')
      const mm = `${ddmmyy[1]}`.padStart(2, '0')
      const yy = `${+ddmmyy[2] - 543}`.padStart(4, '0')
      journalHeader.documentDate = `${yy}-${mm}-${dd}`

      return {
        journalHeaderValidated: journalHeader,
        journalDetailsValidated: journalDetails
      }
    }
  }
}
