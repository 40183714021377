<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { inputFieldMixins, numberMixins, toastMixins } from '@/mixins'
import AccountTransactionTable from '@/components/table/AccountTransactionTable/Index.vue'

/*
Props: {
  modeProp: 'CREATE' | 'UPDATE'
  journalTransactionDetailsProp: {
    id: number
    order: number
    accountNumber: string | null
    accountName: string | null
    debitAmount: string | null
    creditAmount: string | null
  }[]
}
Events: {
  detailUpdated: {
    id: number
    order: number
    accountNumber: string | null
    accountName: string | null
    debitAmount: string | null
    creditAmount: string | null
  }[]
}
*/

export default {
  mixins: [inputFieldMixins, numberMixins, toastMixins],
  components: { AccountTransactionTable },
  props: {
    modeProp: String,
    journalTransactionDetailsProp: Array
  },
  data() {
    return {
      totalDebit: 0,
      totalCredit: 0,
      rowSelected: null
    }
  },
  computed: {
    ...mapState({
      stateAppLoading: 'appLoading'
    })
  },
  watch: {
    journalTransactionDetailsProp() {
      this.totalDebit = _.sum(
        this.journalTransactionDetailsProp.map(detail => {
          return Number(detail.debitAmount)
        })
      )

      this.totalCredit = _.sum(
        this.journalTransactionDetailsProp.map(detail => {
          return Number(detail.creditAmount)
        })
      )
    }
  },
  methods: {
    detailUpdatedHandler(payload) {
      this.$emit('detailUpdated', payload)
    }
  }
}
</script>

<template>
  <b-row align-h="center" class="mx-1 mx-xl-4">
    <b-col cols="12" class="bg-light" style="border-radius: 10px;">
      <account-transaction-table
        :mode="modeProp"
        :informationProp="{ totalCredit: totalCredit, totalDebit: totalDebit }"
        :detailsProp="journalTransactionDetailsProp"
        @detailUpdated="detailUpdatedHandler"
      ></account-transaction-table>
    </b-col>
  </b-row>
</template>

<style scoped></style>
