<script>
import _ from 'lodash'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import { toastMixins, numberMixins } from '@/mixins'
import journalService from '@/services/entry/ge2gjnl.js'

import DetailsForm from '../components/DetailsForm.vue'
import HeaderInformationForm from '../components/HeaderInformationForm.vue'
import {
  journalBookImportAdapter,
  journalHeaderImportAdapter
} from '../helpers/adapter'
import validateMixins from '../mixins/validate'

export default {
  mixins: [toastMixins, validateMixins, numberMixins],
  components: {
    HeaderInformationForm,
    DetailsForm
  },
  data() {
    return {
      journalBookId: null,
      journalBook: null,
      journalTransactionDetails: [],
      newDocumentNumber: null,
      newDocumentId: null,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  async created() {
    this.journalBookId = this.$route.params.journalBookId
    await this.setJournalBook()
    await this.setNewDocumentNumberAndDocumentId()
  },
  methods: {
    ...mapActions({
      actionSetAppLoading: 'setAppLoading'
    }),
    async setJournalBook() {
      try {
        this.actionSetAppLoading(true)
        const res = await journalService.getAllGe2gjnl()
        this.journalBook = journalBookImportAdapter(
          res.data.data.find(
            journalBook =>
              Number(journalBook.glbjncd_id) === Number(this.journalBookId)
          )
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.actionSetAppLoading(false)
      }
    },
    async setNewDocumentNumberAndDocumentId() {
      try {
        this.actionSetAppLoading(true)
        const res = await journalService.getGe2gjnlById(this.journalBookId)
        const { documentNumber, documentId } = journalHeaderImportAdapter(
          res.data.data.information
        )
        const nextDocumentNumber = Number(documentNumber) + 1
        this.newDocumentNumber = `${nextDocumentNumber}`.padStart(7, '0')
      } catch (err) {
        this.newDocumentNumber = '1'.padStart(6, '0')
      } finally {
        // find next documentId for patterns
        if (this.journalBook && this.journalBook.documentIdPattern) {
          const { journalCode: journalBookCode } = this.journalBook
          const {
            latestRunningNumber,
            length,
            yearType
          } = this.journalBook.documentIdPattern

          switch (this.journalBook.documentIdPattern.pattern) {
            case 'COMPUTER':
              const documentIdNumber = latestRunningNumber.split(
                journalBookCode
              )[1]
              const nextDocumentIdNumber =
                journalBookCode +
                `${Number(documentIdNumber) + 1}`.padStart(length, '0')
              this.newDocumentId = nextDocumentIdNumber
              break
            case 'MANUAL':
              this.newDocumentId = latestRunningNumber
              break
            case 'SPECIAL':
              const today = moment()
                .utcOffset('+0700')
                .format('YYYY-MM-DD')
              const todaySplitted = today.split('-')
              const date = todaySplitted[2]
              const month = todaySplitted[1]
              const slicedYear = (yearType === 'BE'
                ? `${Number(todaySplitted[0]) + 543}`
                : todaySplitted[0]
              ).slice(2)
              this.newDocumentId = `${journalBookCode}${slicedYear}${month}${date}`
              break
          }
        }

        this.actionSetAppLoading(false)
      }
    },
    async saveButtonClickedHandler(payload) {
      const { journalTransactionHeader: journalHeader } = payload
      const journalDetails = this.journalTransactionDetails.map(detail => {
        const { debitAmount, creditAmount, ...restDetail } = detail
        return {
          ...restDetail,
          debitAmount:
            Number(debitAmount) === 0 ? null : Number(debitAmount).toFixed(2),
          creditAmount:
            Number(creditAmount) === 0 ? null : Number(creditAmount).toFixed(2)
        }
      })

      const validatedResult = this.mxValidateRequest({
        journalHeader,
        journalDetails
      })
      if (!validatedResult) return

      const {
        journalHeaderValidated,
        journalDetailsValidated
      } = validatedResult

      this.journalTransactionDetails = journalDetailsValidated

      const body = {
        ...journalHeaderValidated,
        details: this.journalTransactionDetails
      }
      delete body.documentDateTH

      try {
        this.actionSetAppLoading(true)
        await journalService.createDocument(body)
        this.mxDisplaySuccessMessage('เพิ่มรายการสมุดรายวันสำเร็จ')
        setTimeout(() => {
          this.$router.replace(
            `/entry/ge2gjnl/journal-book/${this.journalBookId}/detail`
          )
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      } finally {
        this.actionSetAppLoading(false)
      }
    },
    async templateSelectedHandler(template) {
      const { id } = template
      try {
        const res = await journalService.getJournalDocumentTemplateById(id)
        const { details } = res.data.data

        const journalDetailsClone = [...this.journalTransactionDetails]

        details.forEach(detail => {
          const {
            remark,
            accountNumber,
            accountName,
            debitAmount,
            creditAmount
          } = detail

          journalDetailsClone.push({
            id: journalDetailsClone.length + 1,
            order: journalDetailsClone.length + 1,
            accountNumber,
            accountName,
            remark,
            debitAmount,
            creditAmount
          })
        })

        this.journalTransactionDetails = [...journalDetailsClone]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    detailUpdatedHandler(payload) {
      if (!_.isEqual(this.journalTransactionDetails, payload)) {
        this.journalTransactionDetails = payload
      }
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid="lg">
      <!-- page title -->
      <b-row align-v="center">
        <b-col class="col-12 col-lg-6 py-3 text-center text-lg-left">
          <span class="h2 text-white" style="text-shadow: 2px 2px grey;">
            เพิ่มรายการสมุดรายวัน
          </span>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- header + information input -->
      <div class="my-3">
        <header-information-form
          :mode="'CREATE'"
          :journalBookProp="journalBook"
          :newDocumentNumberProp="newDocumentNumber"
          :newDocumentIdProp="newDocumentId"
          @saveButtonClicked="saveButtonClickedHandler"
          @templateSelected="templateSelectedHandler"
        ></header-information-form>
      </div>

      <!-- details input -->
      <div>
        <details-form
          :modeProp="'CREATE'"
          :journalTransactionDetailsProp="journalTransactionDetails"
          @detailUpdated="detailUpdatedHandler"
        ></details-form>
      </div>
    </b-container>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
