<script>
import { mapState } from 'vuex'

import { toastMixins, datetimeMixins } from '@/mixins'
import ge2gjnlService from '@/services/entry/ge2gjnl.js'

export default {
  mixins: [toastMixins, datetimeMixins],
  props: {
    displayModalProp: Boolean,
    journalCodeProp: String
  },
  data() {
    return {
      displayModal: false,
      sortBySelected: 'templateCode',
      sortByOptions: [
        { value: 'templateCode', text: ' เรียงตาม รหัส' },
        { value: 'journalCode', text: ' เรียงตาม สมุดรายวัน' },
        { value: 'templateName', text: ' เรียงตาม รายละเอียด' },
        { value: 'userIdGroupCode', text: ' เรียงตาม กลุ่มผู้ใช้งาน' },
        { value: 'userId', text: ' เรียงตาม สร้างโดย' },
        { value: 'updatedAt', text: ' เรียงตาม ปรับปรุงเมื่อ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      keyword: null,
      templates: [],
      isLoading: false,
      tableHeaders: [
        {
          key: 'templateCode',
          label: 'รหัส'
        },
        { key: 'journalCode', label: 'สมุดรายวัน' },
        {
          key: 'templateName',
          label: 'รายละเอียด'
        },
        {
          key: 'lastUpdatedBy',
          label: 'สร้างโดย'
        },
        {
          key: 'updatedAt',
          label: 'ปรับปรุงเมื่อ'
        }
      ],
      rowSelected: null,
      searchKeywordTimer: null,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultKeywordTimerMs: state => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    displayModalProp() {
      if (this.displayModalProp) {
        this.assignDataFromProp('displayModalProp')
        this.fetchJournalDocumentTemplates()
      }
    },
    displayModal() {
      if (!this.displayModal) this.$emit('modalClosed')
    },
    sortBySelected() {
      this.fetchJournalDocumentTemplates()
    },
    sortTypeSelected() {
      this.fetchJournalDocumentTemplates()
    },
    keyword() {
      if (this.searchKeywordTimer) {
        clearTimeout(this.searchKeywordTimer)
      }
      this.searchKeywordTimer = setTimeout(() => {
        this.fetchJournalDocumentTemplates()
      }, this.defaultKeywordTimerMs)
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.assignDataFromProp('displayModalProp')
    },
    assignDataFromProp(prop) {
      switch (prop) {
        case 'displayModalProp':
          this.displayModal = this.displayModalProp
          break
        default:
          break
      }
    },
    async fetchJournalDocumentTemplates() {
      this.isLoading = true

      try {
        const qs = [
          `sortType=${this.sortTypeSelected}`,
          `sortBy=${this.sortBySelected}`
        ]
        if (this.keyword && this.keyword.trim().length > 0) {
          qs.push(`keyword=${this.keyword.trim()}`)
        }

        const res = await ge2gjnlService.getAvailableJournalDocumentTemplateByJournalCode(
          this.journalCodeProp,
          qs.join('&')
        )

        this.templates = [...res.data.data]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }

      this.isLoading = false
    },
    cancelButtonClicked() {
      this.displayModal = false
    },
    selectButtonClicked() {
      this.displayModal = false
      this.$emit('templateSelected', this.rowSelected)
    },
    rowSelectedHandler(e) {
      if (e.length > 0) this.rowSelected = e[0]
      else this.rowSelected = null
    },
    rowDoubleClickedHandler(e) {
      this.rowSelected = e
      this.selectButtonClicked()
    }
  }
}
</script>

<template>
  <b-modal
    v-model="displayModal"
    size="xl"
    scrollable
    title="เลือกต้นแบบป้อนรายการ"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <b-row>
      <b-col cols="12">
        <b-table
          hover
          outlined
          bordered
          :busy="isLoading"
          :fields="tableHeaders"
          :items="templates"
          sticky-header="430px"
          thead-tr-class="text-center text-nowrap"
          tbody-tr-class="text-nowrap"
          class="border"
          selectable
          select-mode="single"
          @row-selected="rowSelectedHandler"
          @row-dblclicked="rowDoubleClickedHandler"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner
                class="align-middle mr-2"
                variant="primary"
              ></b-spinner>
              <strong>กำลังโหลดข้อมูล ...</strong>
            </div>
          </template>
          <template #cell(templateCode)="data">
            {{ data.item.templateCode }}
          </template>
          <template #cell(journalCode)="data">
            {{ data.item.journalCode }}
          </template>
          <template #cell(templateName)="data">
            {{ data.item.templateName }}
          </template>
          <template #cell(lastUpdatedBy)="data">
            {{ data.item.lastUpdatedBy }}
          </template>
          <template #cell(updatedAt)="data">
            {{ mxConvertToDatetimeBuddhistFormat(data.item.updatedAt) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-row class="w-100">
        <!-- sort by -->
        <b-col cols="6" xl="3">
          <b-form-select :options="sortByOptions" v-model="sortBySelected">
          </b-form-select>
        </b-col>
        <!-- sort type -->
        <b-col cols="6" xl="3">
          <b-form-select :options="sortTypeOptions" v-model="sortTypeSelected">
          </b-form-select>
        </b-col>
        <!-- space -->
        <b-col cols="0" xl="2" class="mt-2 mt-xl-0"></b-col>
        <!-- keyword -->
        <b-col cols="6" xl="3" class="mt-2 mt-xl-0">
          <b-input v-model="keyword" placeholder="รหัสต้นแบบ"></b-input>
        </b-col>
        <!-- button -->
        <b-col cols="6" xl="1" class="mt-2 mt-xl-0">
          <b-button
            variant="primary"
            class="w-100"
            @click="selectButtonClicked()"
          >
            ตกลง
          </b-button>
        </b-col>
      </b-row>
    </template>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-modal>
</template>

<style></style>
